<script setup lang="ts">
definePageMeta({
  layout: "homemaster",
});

interface Global {
  title?: string;
  description?: string;
  siteurl?: string;
}

const item = ref<Global | null>(null);
const loading = ref(true);
const error = ref<string | null>(null);

const fetchGlobalData = async () => {
  try {
    const { getSingletonItem } = useDirectusItems();
    const data = await getSingletonItem<Global>({
      collection: "globalHomeMaster",
    });
    item.value = data;
  } catch (e) {
    error.value = "Failed to fetch navigation data.";
    console.error("Failed to fetch data:", e);
  } finally {
    loading.value = false;
  }
};

onMounted(fetchGlobalData);

const title = computed(() => item.value?.title || "loading");
const description = computed(() => item.value?.description || "loading");

useSeoMeta({
  title: title,
  description: description,
});
useHead({
  link: [
    { rel: "icon", type: "image/x-icon", href: "/favicon.ico" },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/favicon/apple-touch-icon.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: "/favicon/favicon-32x32.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: "/favicon/favicon-16x16.png",
    },
    { rel: "manifest", href: "/favicon/site.webmanifest" },
  ],
});

definePageMeta({
  colorMode: "light",
});
</script>

<template>
  <div class="container-fluid">
    <div
      v-if="loading"
      class="fixed inset-0 flex items-center justify-center bg-white bg-opacity-75 z-50"
    >
      <div class="text-center">
        <Icon
          name="i-line-md:loading-twotone-loop"
          size="48"
          class="animate-spin"
        />
        <p class="mt-2 text-lg animate-blink">Loading...</p>
      </div>
    </div>

    <div
      v-else-if="error"
      class="fixed inset-0 flex items-center justify-center bg-white bg-opacity-75 z-50"
    >
      <div class="text-center text-red-500">
        <p class="text-lg">{{ error }}</p>
      </div>
    </div>

    <div v-else>
      <div class="container max-w-[1392px] mx-auto">
        <homemaster-nav />
        <homemaster-header />

        <Lazyhomemaster-video-intro />
        <LazyHomemasterValues />
        <LazyHomemasterServices />
        <LazyHomemasterReferences />
        <LazyHomemasterContact />
        <LazyHomemasterFooter />
      </div>
    </div>
  </div>
</template>

<style scoped>
@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.animate-blink {
  animation: blink 1s infinite;
}
</style>
