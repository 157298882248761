<template>
  <div class="block lg:flex lg:flex-row bg-white dark:bg-zinc-800 overflow-hidden gap-4">
    <div class="bg-amber-950 text-white p-8 lg:w-1/2 flex flex-col justify-between rounded-2xl">
      <div>
        <h1 class="text-4xl md:text-5xl font-bold mb-6">{{ headingTitle }}</h1>
        <p class="text-lg mb-6">{{ headingSubTitle }}</p>
        <button class="bg-orange-500 text-white px-4 py-2 rounded-lg">{{ headingCTA }}</button>
      </div>
      <h3 class="mt-8 mb-5 block">{{ headingSocialTitle }}</h3>
      <div class="flex items-center pt-5 pl-2 sm:justify-start sm:pt-0" v-if="socialItems.length > 0">
        <div v-for="social in socialItems" :key="social.url" class="flex items-center justify-center">
          <NuxtLink :to="social.url" class="text-2xl text-white hover:text-yellow mr-2" target="_blank">
            <Icon :name="social.icon" size="30" :alt="social.name"/>
          </NuxtLink>
        </div>
      </div>
    </div>
    <div class="hidden lg:flex lg:w-1/2">
      <NuxtImg v-motion-slide-visible-bottom
               v-if="headingImage !== 'loading'"
          provider="directus"
          :src="headingImage"
          :alt="headingTitle"

          formmat="webp"
          height="458"

          quality="20"

          class="object-cover block w-full h-full rounded-2xl"

      />
    </div>
  </div>
</template>

<script setup lang="ts">


const { headingImage, headingTitle, headingSubTitle, headingCTA, headingSocialTitle, fetchHMHeadingData } = useHMGlobalStore();
const { fetchSocialData, socialItems } = useHMSocialStore();

onMounted(async () => {
  await fetchHMHeadingData();
  await fetchSocialData();
});
</script>

<style scoped>
/* Add your styles here */
</style>
