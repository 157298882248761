<script setup lang="ts">
const router = useRouter();
const { y } = useWindowScroll({ behavior: 'smooth' });
const isScrolled = ref(false);

watch(y, (newY) => {
  isScrolled.value = newY > 100; // Adjust the value as needed
});

const { title, logo, fetcHMGlobalData } = useHMGlobalStore();
const { getItems } = useDirectusItems();

const open = ref(false);

interface HMNav {
  id: number;
  name: string;
  path: string;
}

const navs = ref<HMNav[]>([]);

// Fetch nav data
const fetchNavData = async () => {
  try {
    const data = await getItems<HMNav>({
      collection: 'navHomeMaster',
        fields: ['id', 'name', 'path'],
    });
    navs.value = data;
  } catch (e) {
    console.error('Failed to fetch data:', e);
  }
};

const handleNavigation = (path: string) => {
  if (path.startsWith('#')) {
    const element = document.querySelector(path);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  } else {
    router.push(path);
  }
};
onMounted(async () => {
  await fetcHMGlobalData();
  await fetchNavData();
});
useScrollToDiv(navs.value); // Access the value of the navs ref
</script>




  <template>
  <div class="relative inset-x-0 top-0 z-50 mt-50">
    <div class="text-center py-3 w-full bg-white">
      <NuxtLink class="text-xl" to="/">
        <Icon name="i-line-md:chevron-small-triple-left" size="20" /> vissza
      </NuxtLink>
    </div>
    <header class="flex flex-col lg:flex-row justify-between items-center my-5 container max-w-[1392px] mx-auto">
      <div class="flex w-full lg:w-auto items-center justify-between">
        <NuxtLink to="/template1/" class="text-lg ml-3 lg:ml-0">
          <NuxtImg
              v-if="logo !== 'loading'"
              provider="directus"
              :src="logo"
              :alt="title"
              width="100"
          />
        </NuxtLink>
        <div class="block lg:hidden mr-3">
          <button @click="open = !open" class="text-[#562A0E]">
            <Icon v-if="!open" name="heroicons-outline:menu-alt-3" size="40" />
            <Icon v-else name="heroicons-outline:x" size="40" />
          </button>
        </div>
      </div>
      <nav v-if="navs" :class="{ 'block': open, 'hidden': !open }" class="w-full lg:w-auto mt-2 lg:flex lg:mt-0">
        <ul class="flex flex-col ml-5 lg:ml-0 lg:flex-row lg:gap-3">
          <li v-for="item in navs" :key="item.name">
            <NuxtLink :to="item.path" @click="handleNavigation(item.path)" class="flex lg:px-3 py-2 hover:text-gray-400">
              {{ item.name }}
            </NuxtLink>
          </li>
        </ul>
      </nav>
    </header>
  </div>
</template>